module.exports = [{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://rencontres.federation-lgbti.org"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"fr","configPath":"/home/debian/devfest/Devfest2024/locales/config.json","prefixDefault":false},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./locales","i18nextOptions":{"ns":["translation"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/images/maskable_icon.png","icon_options":{"purpose":"any maskable"},"name":"Les Rencontres LGBTI+ 2024","short_name":"Les Rencontres LGBTI+","start_url":"/","background_color":"#0e1324","theme_color":"#22263f","display":"standalone","description":"Informations générales sur Les Rencontres LGBTI+","lang":"fr","localize":[{"start_url":"/en/","lang":"en","name":"Les Rencontres LGBTI+","short_name":"Les Rencontres LGBTI+","description":"General informations about Les Rencontres LGBTI+"},{"start_url":"/fr/","lang":"fr","description":"Informations générales sur les Rencontres LGBTI+."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0d1d78b62b8d197189686ce88c915011"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/debian/devfest/Devfest2024/src/layout/mdx.tsx"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/debian/devfest/Devfest2024","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
